import { useEffect, useRef, useState } from "react";
import { Box, Button, Collapse, Fade, Icon, Stack, TextField, Typography } from "@mui/material";
import LetterJ from "../../assets/images/LetterJ.svg";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import QrCodeScanner from "../../qr/components/QrCodeScanner/QrCodeScanner.tsx";
import { httpPost } from "../../global/utils/api/http.tsx";
import JamezzApi from "../../global/utils/api/JamezzApi.ts";
import { createReturnPath } from "../../global/utils/config.ts";

function Login() {
  const [webcode, setWebcode] = useState("");
  const textField = useRef<HTMLInputElement>();
  const [unknownWebcode, setUnknownWebcode] = useState(false);
  const isSubmitting = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      if (textField.current) {
        textField.current.focus();
      }
    }, 500);
  }, []);

  function submit() {
    if (isSubmitting.current) {
      return;
    }
    httpPost(
      JamezzApi.get.getSessionState.url,
      JamezzApi.post.loginSession.data(webcode, window.location.origin + createReturnPath(), "", "", ""),
      {
        showToastError: true,
        showToastSuccess: false,
      }
    )
      .then((response: any) => {
        if (response.data?.data?.activeState != null && response.data?.data?.activeState > 0) {
          sessionStorage.setItem("jamezz_login_webcode", webcode);
          if (import.meta.env.MODE === "production") {
            (window as Window).location = "https://jamezz.app/dl/" + webcode;
          } else {
            (window as Window).location = "/dl/" + webcode;
          }
        } else {
          setUnknownWebcode(true);
        }
      })
      .finally(() => {
        isSubmitting.current = false;
      });
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setUnknownWebcode(false);
        submit();
      }}
    >
      <Stack direction={"column"} gap={2}>
        <Collapse unmountOnExit in={unknownWebcode}>
          <Typography sx={{ color: "red" }}>Webcode not found</Typography>
        </Collapse>
        <TextField
          inputRef={textField}
          sx={{ backgroundColor: "grey.900", textAlign: "center" }}
          inputProps={{
            style: { textAlign: "center" },
          }}
          required
          autoComplete={"off"}
          label={"Enter Webcode"}
          value={webcode}
          variant={"filled"}
          onChange={(e) => {
            setUnknownWebcode(false);
            setWebcode(e.target.value.toUpperCase());
          }}
        />

        <Button
          type={"submit"}
          variant={"contained"}
          // startIcon={<SvgIcon component={LetterJ} viewBox={"0 0 90 90"} />}
          startIcon={
            <Icon style={{ width: 30, height: 30 }}>
              <img src={LetterJ} />
            </Icon>
          }
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",

            fontSize: "1.3rem",
          }}
        >
          <FormattedMessageJamezz id={"Login"} />
        </Button>
        <Box sx={{ width: 1, display: "flex", justifyContent: "center" }}>
          <FormattedMessageJamezz id={"or"} />
        </Box>
        <QrCodeScanner
          onScanQrCode={(qrCode) => {
            (window as Window).location = qrCode;
          }}
        />
      </Stack>
    </form>
  );
}

export default Login;
